<template>
	<div class="d-flex flex-column-fluid">
		<!-- <div v-if="adminSite.loading === true" class="container">
			<ITCSpinner :loaded="false" />
		</div> -->
		<!--begin::Container-->
		<div class="container">
			<!--begin::SiteEdit-->
			<div class="row">
				<div class="col-12 col-md-6 col-lg-3 px-0 mb-4 order-md-1 order-lg-1">
					<SiteProfileToolPanel />
				</div>
				<div class="col-12 col-lg-6 px-0 pl-lg-3 mb-4 order-md-3 order-lg-2">
					<SiteProfile />
				</div>
				<div class="col-12 col-md-6 col-lg-3 px-0 mb-4 pl-md-3 order-md-2 order-lg-3">
					<SiteAccountLinks />
				</div>
			</div>
			<div class="row">
				<SiteQuota />
			</div>
			<div class="row">
				<StarlinkSubscription v-if="adminSite.starlinkServiceLines.length>0" />
			</div>
			<div class="row" style="margin-bottom: 26px">
				<div class="flex-grow-1">
					<b-card no-body>
						<b-tabs card pills fill v-model="activeTab">
							<b-tab title="Site Devices" title-item-class="site-tab">
								<SiteDevices :activeTab.sync="activeTab" />
							</b-tab>
							<b-tab title-item-class="site-tab">
								<template #title>
									Site Layout
									<div id="available-widgets" v-show="adminSite.availableWidgets.length > 0">
										- {{ adminSite.availableWidgets.length }} widgets available!
									</div>
								</template>
								<SiteLayout
									v-if="adminSite.data && adminSite.data.layout && adminSite.data.layout.widgets && adminSite.loading !== true"
									:activeTab.sync="activeTab"
								/>
							</b-tab>
						</b-tabs>
					</b-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { SET_ADMINEDIT_SITE_INFO, SET_DEVICE_TYPES } from '@/core/services/store/admin.module';

export default {
	data() {
		return {
			activeTab: 0,
		};
	},
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		SiteProfileToolPanel: () => import('@/view/content/widgets/admin/editsite/SiteProfileToolPanel.vue'),
		SiteProfile: () => import('@/view/content/widgets/admin/editsite/SiteProfile.vue'),
		SiteQuota: () => import('@/view/content/widgets/admin/editsite/SiteProfileQuota.vue'),
		SiteLayout: () => import('@/view/content/widgets/admin/editsite/SiteLayout.vue'),
		SiteDevices: () => import('@/view/content/widgets/admin/editsite/SiteDevices.vue'),
		SiteAccountLinks: () => import('@/view/content/widgets/admin/editsite/SiteProfileAccountLinks.vue'),
		StarlinkSubscription: () => import('@/view/content/widgets/admin/editsite/SiteStarlinkSubscription.vue')
		// SiteProfileDeviceList: () => import('@/view/content/widgets/admin/editsite/SiteProfileDeviceList.vue'),
	},
	methods: {
		getSiteData() {
			this.$store.dispatch(SET_ADMINEDIT_SITE_INFO, this.$route.params.id);
		},
	},
	computed: {
		...mapGetters(['adminSite']),
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Admin Edit Site' }]);
		this.getSiteData();
		this.$http.get('devicetypes').then(res => {
			this.$store.commit(SET_DEVICE_TYPES, res.data.data);
		});
	},
};
</script>

<style>
.nav-pills .site-tab {
	border: #3699ff solid 1px !important;
	border-radius: 8px;
}

.nav-pills .site-tab .nav-link:not(.active) {
	color: rgb(46, 46, 46) !important;
}

#available-widgets {
	margin-left: 5px;
	position: relative;
	overflow: hidden;
	font-weight: 500;
}

#available-widgets:after {
	animation: sheen 5s 10s infinite;
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.5) 50%, transparent);
	transform: rotateZ(60deg) translate(-1em, 4em);
}

@keyframes sheen {
	50% {
		transform: rotateZ(60deg) translate(0, -5em);
	}
	100% {
		background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0) 50%, transparent);
		transform: rotateZ(60deg) translate(0, 0);
	}
}
</style>
